import * as styles from './Image.module.css';

import React from 'react';

const Image = (props) => {
  return (
    <div className={styles.Image}>
      <img alt={props.alt} src={props.img} />
    </div>
  );
};

export default Image;
