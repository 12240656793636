import Banner from '../components/Banner/Banner';
import BlockRow from '../components/UI/BlockRow/BlockRow';
import Card from '../components/Card/Card';
import Image from '../components/UI/Image/Image';
import Layout from '../containers/layout';
import React from 'react';
import TeamBio from '../components/UI/TeamInfo/TeamBio';
import TeamInfo from '../components/UI/TeamInfo/TeamInfo';
import Video from '../components/UI/Video/Video';
import { getUrl } from '../util';
import { graphql } from 'gatsby';

const TeamMember = ({ location, data }) => {
  const bannerPages = data.allSitePage.nodes;
  const team = data.allStrapiTeamMembers.nodes;

  const DefaultImage =
    data.strapiGeneralSeo.SiteSEO.PreviewImage.formats.medium.url;

  const bannerContent = team.map((el, i) => {
    const url = getUrl(bannerPages, el.id);
    return (
      <Card
        name={el.Name}
        title={el.Title}
        link={url ? url : null}
        key={i}
        img={el.Picture ? el.Picture.url : DefaultImage}
      />
    );
  });

  const person = data.strapiTeamMembers;
  const seoData = person.SEO;
  
  
  return (
    <Layout seoData={seoData} location={location} data={data} >
      <main>
        <BlockRow mobileReverse noPadding>
          {person.VideoURL ? (
            <Video video={person.VideoURL} />
          ) : (
            <Image img={person.Picture.url} />
          )}

          <TeamInfo
            name={person.Name}
            email={person.Email}
            phoneNo={person.PhoneNo}
            title={person.Title}
            linkedIn={person.LinkedIn}
            meeting={person.MeetingLink}
          />
        </BlockRow>

        <TeamBio
          background={'gray70'}
          quote={person.Quote}
          bio={person.Bio}
          headline={person.Headline}
        />

        <Banner>{bannerContent}</Banner>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query teamQuery($id: String!) {
    strapiTeamMembers(id: { eq: $id }) {
      Name
      Email
      PhoneNo
      Headline
      LinkedIn
      Quote
      Title
      VideoURL
      MeetingLink
      Picture {
        url
      }
      HasBioPage
      Content
      Bio
      Team
      id
      SEO {
        MetaTitle
        MetaDescription
        Keywords
        PreviewImage {
          url
        }
      }
    }
    allStrapiTeamMembers {
      nodes {
        id
        Name
        Title
        MeetingLink
        Picture {
          url
        }
      }
    }
    allSitePage(
      filter: {
        componentChunkName: { eq: "component---src-templates-team-member-js" }
      }
    ) {
      nodes {
        context {
          id
        }
        path
      }
    }
    strapiGeneralSeo {
      SiteSEO {
        PreviewImage {
          formats {
            medium {
              url
              name
            }
          }
        }
      }
    }
  }
`;

export default TeamMember;
