import * as styles from './Card.module.css';

import { Link } from 'gatsby';
import React from 'react';

const Card = (props) => {
  return (
    <Link to={props.link}>
      <div className={styles.Card}>
        <div className={styles.Filter}>
          <img src={props.img} alt={props.name} />
        </div>
        <div className={styles.Nametag}>
          <p className={styles.Name}>{props.name && props.name}</p>
          <p>{props.title || null}</p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
