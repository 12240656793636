import * as styles from './Banner.module.css';

import React from 'react';

const Banner = (props) => {
  return (
    <div className={styles.Banner}>
      <div className={styles.Carousel}>
        {props.children}
      </div>
    </div>
  );
};

export default Banner;
