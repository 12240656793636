import * as styles from './TeamInfo.module.css';

import EmailIcon from '../../../images/email.svg';
import LinkedInIcon from '../../../images/linkedin.svg';
import MobileIcon from '../../../images/mobile.svg';
import PhoneIcon from '../../../images/phone.svg';
import MeetingIcon from '../../../images/meeting.svg';
import React from 'react';
import slugify from 'slugify';

const Block = (props) => {
  console.log(props);
  return (
    <section className={styles.TeamInfo}>
      <h1>{props.name}</h1>
      <h2>{props.title}</h2>

      <address>
        <div className={styles.BulletPoints}>
          <ul>
            {props.email && (
              <li>
                <EmailIcon />
                <a href={`mailto:${props.email}`}>{props.email}</a>
              </li>
            )}
            {props.phoneNo && (
              <li>
                <PhoneIcon />
                <a href={`tel:${props.phoneNo}`}>{props.phoneNo}</a>
              </li>
            )}
            {props.mobile && (
              <li>
                <MobileIcon />
                <a href={`tel:${props.mobile}`}>{props.mobile}</a>
              </li>
            )}
            {props.linkedIn && (
              <li>
                <LinkedInIcon />
                <a href={`${props.linkedIn}`}>
                  linkedin.com/{slugify(props.name, { lower: true })}
                </a>
              </li>
            )}
            {props.meeting && (
              <li>
                <MeetingIcon />
                <a href={`${props.meeting}`}>Book a meeting!</a>
              </li>
            )}
          </ul>
        </div>
      </address>
    </section>
  );
};

export default Block;
