import * as styles from './Video.module.css';

import React from 'react';

const Video = (props) => {
  return (
    <div className={styles.Video}>
      <div className={styles.Responsive}>
        <iframe
          width='560'
          height='315'
          src={props.video}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
        {props.children}
      </div>
    </div>
  );
};

export default Video;
