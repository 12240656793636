import * as styles from './Popup.module.css';

import React, { useState } from 'react';

import Loader from 'react-loader-spinner';
import Modal from '../UI/Modal/Modal';
import NumberFormat from 'react-number-format';
import X from '../../images/x.svg';
import disableScroll from 'disable-scroll';
import publicIp from 'public-ip';
import { setQuoteFalse } from '../../state/slices';
import { useDispatch } from 'react-redux';
import validator from 'validator';

const PopupForm = (props) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [phoneNo, setPhoneNo] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [formMessage, setFormMessage] = useState(
    `Hello, I am interested in ${props.sku}`
  );
  const [message, setMessage] = useState('');
  const [formSent, setFormSent] = useState(false);
  const [formIsSending, setFormIsSending] = useState(false);

  const sendData = async (url, options) => {
    const response = await fetch(url, options);
    const data = await response.json();

    if (!response.ok) {
      setMessage('Please enter a different email');
      throw new Error(`HTTP Error! status: ${response.status}`);
    }
    return data;
  };
  const submitForm = async (e) => {
    e.preventDefault();
    setFormIsSending(true);
    if (!validator.isEmail(email) || email === '') {
      setMessage('** Please enter a valid email address');
      setFormIsSending(false);
      return;
    }

    const location = window && window.location.href;
    const pageName = document && document.title;
    const cookie =
      document &&
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('hubspotutk='))
        .split('=')[1];

    const ip = await publicIp.v4();
    const data = {
      fields: [
        {
          name: 'email',
          value: email.toLowerCase(),
        },
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'lastname',
          value: lastName,
        },
        {
          name: 'phone',
          value: phoneNo,
        },
        {
          name: 'company',
          value: companyName,
        },
        { name: 'message', value: message },
      ],
      context: {
        hutk: `${cookie}`,
        pageUri: location,
        pageName: pageName,
        ipAddress: `${ip}`,
      },
    };

    const url =
      'https://api.hsforms.com/submissions/v3/integration/submit/2687165/c8b13bf0-bc09-432b-98d5-6f4863c82d97';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    const response = await sendData(url, options);
    setMessage(response.inlineMessage);
    setFormSent(true);
    setFormIsSending(false);
  };

  const dispatch = useDispatch();

  const handleExit = () => {
    props.closehandler ? props.closehandler() : dispatch(setQuoteFalse());
    disableScroll.off();
  };

  return (
    <>
      <Modal />
      <div className={styles.Popup}>
        <div className={styles.Exit}>
          <X onClick={()=>handleExit()} />
        </div>
        {!formSent ? (
          <>
            <h4>
              Please messaage us the parts you are interested in and we'll get
              back to you!
            </h4>
            <form className={styles.PopupForm} onSubmit={(e) => submitForm(e)}>
              <div className={styles.FormWrapper}>
                <div className={styles.Inputs}>
                  <label>Your Info</label>
                  <div className={styles.FormRow}>
                    <input
                      className={`${styles.Input}`}
                      type='text'
                      value={firstName}
                      placeholder='First Name'
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className={styles.FormRow}>
                    <input
                      className={styles.Input}
                      type='text'
                      value={lastName}
                      placeholder='Last Name'
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className={styles.FormRow}>
                    <input
                      className={styles.Input}
                      type='email'
                      value={email}
                      placeholder='Email'
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className={styles.FormRow}>
                    <NumberFormat
                      className={styles.Input}
                      value={phoneNo}
                      placeholder='Phone Number'
                      onChange={(e) => setPhoneNo(e.target.value)}
                      format='+1 (###) ###-####'
                      mask='_'
                    />
                  </div>
                  <div className={styles.FormRow}>
                    <input
                      className={styles.Input}
                      type='text'
                      value={companyName}
                      placeholder='Company Name'
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                </div>

                <div className={styles.TextBlock}>
                  <div className={styles.TextWrapper}>
                    <label>Message</label>
                    <textarea
                      className={styles.TextArea}
                      value={formMessage}
                      onChange={(e) => setFormMessage(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className={`${styles.FormRow} ${styles.ButtonRow}`}>
                {formIsSending ? (
                  <Loader
                    type='ThreeDots'
                    color={'#2bace2'}
                    width={30}
                    height={30}
                    style={{ margin: 'auto' }}
                  />
                ) : (
                  <input
                    className={styles.Button}
                    type='submit'
                    value='Submit'
                  />
                )}
              </div>
            </form>
            <div className={styles.Message}>{message}</div>
          </>
        ) : (
          <div className={styles.CompleteMessage}>
            <h4>{message.replace(/<[^>]+>/g, '')}</h4>
          </div>
        )}
      </div>
    </>
  );
};

export default PopupForm;
