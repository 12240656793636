import * as styles from './TeamBio.module.css';

import BlockRow from '../BlockRow/BlockRow';
import MarkdownIt from 'markdown-it';
import React from 'react';
import PopupQuote from '../../../components/Forms/PopupQuote';

const TeamBio = (props) => {
  const { bio, productinfo, longdescription, headline, quote, background } = props;

  const md = new MarkdownIt();

  const TeamInfo = () => {
    return (
      <BlockRow background={background} fullWidth>
      <h4 className={styles.Quote}>"{quote}"</h4>
      <div className={styles.InfoBlock}>
        <h6>{headline}</h6>
        {bio && <div dangerouslySetInnerHTML={{ __html: md.render(bio) }} />}
      </div>
      </BlockRow>
    )
  }

  const [showForm, setShowForm] = React.useState(false);



  const ProductInfo = () => {

    const toggleShowForm = () => {
      setShowForm(!showForm);
    };

    return (
      <BlockRow background={background} fullWidth>
      {showForm?<PopupQuote sku={productinfo.SKU} closehandler={() => toggleShowForm()} />:null}
      <table className={styles.productInfoTable} >
      {Object.keys(productinfo).map(item => {

        if( (i=>i.hasOwnProperty('length')&&i.length>0)(productinfo[item]) ){

          return (
              <tr><td className={styles.infoTitle} >{item.replace("_Number"," #").replace(/_/g," ")}</td><td>{productinfo[item]}</td></tr>
          )

        }

      })}
      </table>
      <div className={styles.InfoBlock}>
        <div onClick={toggleShowForm} style={{cursor:'pointer'}} dangerouslySetInnerHTML={{ __html: md.render(longdescription) }} />
      </div>
      </BlockRow>
    )
  }

  return (
    <section className={styles.TeamBio}>
      {props.productinfo?<ProductInfo />:<TeamInfo />}
    </section>
  );
};

export default TeamBio;
