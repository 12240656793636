import * as styles from './BlockRow.module.css';

import React from 'react';

const BlockRow = (props) => {
  const { fullWidth, mobileReverse, noPadding } = props;
  const style = {
    backgroundColor: `var(--${props.background})` || 'none',
    boxShadow: `100px 0px var(--${props.background}), -100px 0px var(--${props.background})`,
  };

  const classList = [styles.BlockRow];

  if (fullWidth) {
    classList.push(styles.Background);
  }
  if (mobileReverse) {
    classList.push(styles.MobileReverse);
  }

  if (noPadding) {
    classList.push(styles.NoPadding);
  }

  return (
    <div className={props.fullWidth ? styles.Background : null} style={style}>
      <div className={classList.join(' ')}>{props.children}</div>
    </div>
  );
};

export default BlockRow;
